<template>
  <div>
    <div class="font-weight-medium"
         :class="{'display-1': $vuetify.breakpoint.mdAndUp, 'subheading': $vuetify.breakpoint.smAndDown}"
    />
    <v-layout align-center justify-center>
      <div class="size">
        <v-flex xs12
                sm12
                md12
                lg12
                xl12
                class="font-weight-light"
                :class="{'headline': $vuetify.breakpoint.lgAndUp,'body-2': $vuetify.breakpoint.mdOnly, 'body-2': $vuetify.breakpoint.smAndDown}"
        >
          Sorry! Page you were looking for does not exist. But, since you're already here, enjoy a fun MagicGraph on us.
        </v-flex>
      </div>
    </v-layout>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox5a" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Boxes from './PNP.js'
import Vue from 'vue';
export default {
  name: 'PNP',
  components: {},
	created () {
	  this.$store.commit('navigation/resetState');
		this.$store.commit('navigation/changeTitle', 'Page Not Found');
		this.$store.commit('navigation/changeMenu', 'Page Not Found');
		let newshowhome = false;
		this.$store.commit('navigation/toggleshowhome', newshowhome);
		let newPNP =true;
		this.$store.commit('navigation/replacePNP', newPNP);
		let newLeftArrow =true;
		this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
	},
	mounted () {
		MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
		Boxes.box1();
	},
  metaInfo: {
     title: 'Page Not Found',
     titleTemplate: null,
          meta: [
            {vmid: 'description', name: 'description', content: 'This page has moved.'},
            {vmid: 'keywords', name: 'keywords', content: 'Page Not Found'}
                ]
   }
	}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@include respond-to(less-smallest-screens) {
.size{
font-size:18px;
//font-weight: bold;
font-family:Oswald;
}
}
@include respond-to(smallest-screens) {
.size{
font-size:18px;
//font-weight: bold;
font-family:Oswald;
position:relative;
}
}
@include respond-to(small-screens) {
.size{
font-size:20px;
//font-weight: bold;
font-family:Oswald;
position:relative;
}
}
@include respond-to(medium-screens) {
.size{
font-size:24px;
font-weight:light;
font-family:Oswald;
position:relative;
}
}
@include respond-to(large-screens) {
.size{
font-size:28px;
font-weight: light;
font-family:Oswald;
position:relative;
}
}
@include respond-to(largest-screens) {
.size{
font-size:32px;
font-weight: light;
font-family:Oswald;
position:relative;
}
}
</style>
